import { SideBarItem } from "../../../interfaces/SideBarItem";
import EqualizerIcon from "@material-ui/icons/Equalizer";

const SeasonStats: SideBarItem = {
	name: "Player Stats",
	icon: <EqualizerIcon />,
	adminPanelURL: "seasonStats",
	fetchAllURL: "seasonStats/all",
	fetchSingleURL: "seasonStats/:id",
	updateURL: "seasonStats/:id",
	deleteURL: "seasonStats/:id",
	postURL: "seasonStats",
	columns: [
		{
			id: "playerID",
			label: "Player",
			type: "ReferenceInput",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "player", referenceURL: "players/all", referenceID: "id", referenceName: "fullName" },
		},
		{
			id: "seasonID",
			label: "Season",
			type: "ReferenceInput",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "season", referenceURL: "seasons/all", referenceID: "id", referenceName: "name" },
		},
		{ id: "gamesPlayed", label: "Games Played", type: "Number", isHeadCell: false, required: true },
		{ id: "pointsPerGame", label: "Points Per Game", type: "Number", isHeadCell: false, required: true },
		{ id: "minutesPerGame", label: "Minutes Per Game", type: "Number", isHeadCell: false, required: true },
		{ id: "fieldGoalAttempts", label: "Field Goal Attempts", type: "Number", isHeadCell: false, required: true },
		{ id: "fieldGoalsMade", label: "Field Goals Made", type: "Number", isHeadCell: false, required: true },
		{ id: "fieldGoalPercentage", label: "Field Goal Percentage", type: "Number", isHeadCell: false, required: true },
		{ id: "assistsPerGame", label: "Assists Per Game", type: "Number", isHeadCell: false, required: true },
		{ id: "threePointAttempts", label: "Three Point Attempts", type: "Number", isHeadCell: false, required: true },
		{ id: "reboundsPerGame", label: "Rebounds Per Game", type: "Number", isHeadCell: false, required: true },
	],
	orderBy: "id",
	order: "asc",
};

export default SeasonStats;
