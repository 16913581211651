import { SideBarItem } from "../../../interfaces/SideBarItem";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";

const Players: SideBarItem = {
	name: "Players",
	icon: <EmojiPeopleIcon />,
	adminPanelURL: "players",
	fetchAllURL: "players/all",
	fetchSingleURL: "players/:id",
	updateURL: "players/:id",
	deleteURL: "players/:id",
	postURL: "players",
	columns: [
		{ id: "fullName", label: "Name", type: "Text", isHeadCell: true, required: true },
		{ id: "nickname", label: "Nickname", type: "Text", isHeadCell: false, required: false },
		{ id: "biography", label: "Bio", type: "TextArea", isHeadCell: false, required: false },
		{ id: "jerseyNumber", label: "Jersey Number", type: "Number", isHeadCell: true, required: true },
		{ id: "dateOfBirth", label: "DOB", type: "Date", isHeadCell: false, required: true },
		{ id: "position", label: "Position", type: "Text", isHeadCell: true, required: true },
		{ id: "height", label: "Height", type: "Number", isHeadCell: false, required: true },
		{ id: "weight", label: "Weight", type: "Number", isHeadCell: false, required: true },
		{ id: "nationCode", label: "Nation Code", type: "Text", isHeadCell: false, required: false },
		{ id: "imageUrl", label: "Image", type: "Image", isHeadCell: false, required: false },
		{
			id: "playerVideos",
			label: "Featured Videos",
			type: "ManyToMany",
			isHeadCell: false,
			required: false,
			referenceInputData: { keyInFetchResponse: "playerVideos", referenceID: "mediaID", referenceName: "headline", referenceURL: "videos/all" },
		},
		{ id: "twitter", label: "Twitter Handle", type: "Text", isHeadCell: false, required: false },
		{ id: "instagram", label: "Instagram Handle", type: "Text", isHeadCell: false, required: false },
		{ id: "facebook", label: "Facebook Handle", type: "Text", isHeadCell: false, required: false },
		{ id: "isFeaturedPlayer", label: "Featured Player", type: "Enum", isHeadCell: true, required: false, enumChoices: ["true", "false"] },
	],
	orderBy: "jerseyNumber",
	order: "asc",
	blockCreate: true,
	playersSponsor: true,
};

export default Players;
