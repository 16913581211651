import CircularProgress from "@material-ui/core/CircularProgress";
import React, { FC } from "react";

const LoadingSpinner: FC = () => {
	return (
		<div
			style={{
				height: "80vh",
				textAlign: "center",
				paddingTop: "35vh",
			}}
		>
			<CircularProgress color="secondary" />
		</div>
	);
};

export default LoadingSpinner;